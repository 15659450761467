<template>
  <li class="aui-list-item">
	<div class="aui-list-item-inner">
	  <div class="aui-list-item-label" :style="labelStyle" :class="labelClass">{{ labelName }}</div>
	  <div class="aui-list-item-inner" :class="{'aui-list-item-arrow':hasArrow}" @click.stop="$emit('arrow-click')">
		<template v-if="hasInput">
		  <div class="aui-list-item-input">
			<slot name="inputText"></slot>
		  </div>
		  <div v-if="hasIcon" class="aui-list-item-label-icon" :class="iconClass" :style="iconStyles" @click.stop="$emit('icon-click')">
			<slot name="icon"></slot>
		  </div>
		</template>
		<slot v-else></slot>
	  </div>
	</div>
  </li>
</template>

<script>
export default {
  name: 'LiItem',
  props: {
	labelName: { type: String },
	classNames: { type: String },
	inputText: { type: String },
	iconStyles: { type: String },
	iconClass: { type: String },
	hasInput: { type: Boolean, default: true },
	hasIcon: { type: Boolean, default: false },
	labelStyle: String,
	labelClass: String,
	hasArrow: { type: Boolean, default: false }
  },
  methods: {}
}
</script>
