import MsConfig from './ms.config'
import utils from './utils'
import api from './api'

// 单元格宽度
const cellWidth = MsConfig.cellWidth

function initListKeyVal (obj, name) {
  obj[name].keyVal = {}
  obj[name].forEach(type => {
    if (name === 'orderPlatforms') {
      obj[name].keyVal[type.value] = type
    } else if (name.match(/outcomeTypes|incomeTypes/)) {
      obj[name].keyVal[type.code] = type.name
    } else {
      obj[name].keyVal[type.value] = type.name
    }
  })
}

class CompanyConfig {
  constructor (opts) {
    this.orderPlatforms = []
    this.roomState = ''
    this.accountTypes = []
    this.incomeTypes = []
    this.outcomeTypes = []
    this.version = ''
    this.autoCheckout = true
    this.cleanTimeRange = '14:00-16:00'
    Object.assign(this, opts)

    initListKeyVal(this, 'accountTypes')
    initListKeyVal(this, 'outcomeTypes')
    initListKeyVal(this, 'incomeTypes')
    initListKeyVal(this, 'orderPlatforms')
  }

  defaultCleanRange () {
    let val = {}
    try {
      const range = this.cleanTimeRange.split('-')
      val = { begin: range[0], end: range[1] }
    } catch (e) {
      val = { begin: '14:00', end: '16:00' }
    }
    return val
  }

  platformsStyle (state, start, end, roomState) {
    roomState = roomState || this.roomState
    state.intervalDays = state.day
    state.day = utils.daysBetween(Math.max(start, state.startTime), Math.min(end, state.endTime))
    if (state.type === 'CLEAN_ROOM') {
      state.cleanClass = state.status.toLocaleLowerCase()
    } else {
      state.width = (state.day * cellWidth - 2) + 'px'
      state.style = 'width:' + (state.day * cellWidth - 2) + 'px;'
      const order = state.order
      let style = 'background:#d4d3d3;'
      if (order && order.plan && order.sourcePlatform) {
        if (state.day === 0) {
          state.width = (cellWidth - 2) + 'px'
          state.style = 'width:' + (cellWidth - 2) + 'px;'
        }
        const platform = this.orderPlatforms.find(platform => platform.name === state.order.sourcePlatform)
        if (platform && roomState === 'platform') {
          style = 'color:#fff;background-color:' + platform.code
        } else {
          style = 'background-color:' + MsConfig.OrderStatus[state.status].color
          state.platformStyle = 'background-color:' + platform.code + ';box-shadow: 0 0 2px ' + platform.code
        }
      }
      state.style += style
    }
  }

  static setVal (name, list) {
    if (!(list instanceof Array)) {
      list = [list]
    }
    list.forEach(dic => dic.typeName = name)
    api.$store.dispatch('company.config.set.dic', list).then(nvl => CompanyConfig.httpPost(name)).then(nvl => {
      api.toast(1, '设置保存成功', 2000)
    })
  }

  static httpInit (version) {
    return api.httpGet('/api/ms/company/config', { version }).then(resp => {
      if (resp.data.state === 'success') {
        const result = resp.data.result
        if (version !== result.version) {
          const config = result.config
          config.orderPlatforms = JSON.parse(config.orderPlatforms)
          config.accountTypes = JSON.parse(config.accountTypes)
          config.incomeTypes = JSON.parse(config.incomeTypes)
          config.outcomeTypes = JSON.parse(config.outcomeTypes)
          return api.$store.dispatch('company.config.setting', config)
        }
      }
    })
  }

  static httpPost (name) {
    const config = api.$store.state.comm.companyConfig[name]
    return api.httpPost('/api/ms/company/config/props-' + name, { desc: JSON.stringify(config) })
    .then(resp => resp.data.state === 'success' ? Promise.resolve() : Promise.reject(new Error(resp.data.message)))
  }
}

export class Diction {
  constructor (opts) {
    this.name = ''
    this.value = ''
    this.code = ''
    this.original = ''
    // 动作，编辑，新增，删除
    this.action = ''
    Object.assign(this, opts)
  }

  static init (name, value, code, action, original) {
    return new Diction({ name, value, code, action, original })
  }
}

export default CompanyConfig
