import axios from 'axios'
import CryptoJS from 'crypto-js'
import qs from 'qs'
import environment from './environment'
import httpData from './ms.data.api'
import MsConfig from './ms.config'

// import Store from './store/index'

class AppEventEmitter {
  constructor () {
	this.eventNames = {}
  }

  $emit (name, params) {
	const event = this
	const obj = event.eventNames[name] = event.eventNames[name] || {}
	// console.log('api $emit', name, event.eventNames)
	Object.keys(obj).forEach(key => {
	  // console.log(key)
	  try {
		obj[key].forEach(fun => Promise.resolve().then(() => fun(params)))
	  } catch (e) {
		console.error(e)
	  }
	})
  }

  $on (key, name, fun) {
	const event = this
	event.eventNames[name] = event.eventNames[name] || {}
	event.eventNames[name][key] = event.eventNames[name][key] || []
	event.eventNames[name][key].push(fun)
  }

  $off (key, name, fun) {
	const event = this
	if (!name) {
	  Object.keys(event.eventNames).forEach(e => {
		delete event.eventNames[e][key]
	  })
	} else {
	  delete event.eventNames[name][key]
	}
	Object.keys(event.eventNames).forEach(name => {
	  if (Object.keys(event.eventNames[name]).length === 0) {
		delete event.eventNames[name]
	  }
	})
  }
}

const Event = new AppEventEmitter()

function getSessionStorage (key) {
  const siteJson = window.sessionStorage.getItem(key)
  return siteJson ? JSON.parse(siteJson) : {}
}

function encrypt (text, vi, key) {
  const encryptedData = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
	iv: CryptoJS.enc.Utf8.parse(vi), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
  })
  return encryptedData.toString()
}

function decrypted (encodeText, vi, key) {
  key = CryptoJS.enc.Utf8.parse(key)
  vi = CryptoJS.enc.Utf8.parse(vi)
  let decrypted = CryptoJS.AES.decrypt(encodeText, key, {
	iv: vi, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
  })
  decrypted = CryptoJS.enc.Utf8.stringify(decrypted)
  return decrypted
}

let requestId = 0

axios.defaults.baseURL = environment.getDomain()
axios.defaults.withCredentials = true

axios.interceptors.response.use(resp => {
  const authenticatedCode = resp.headers['authenticated-code']
  if (authenticatedCode && authenticatedCode.match(/1|2|3|4/)) {
	window.location.href = '/#/logout'
  }
  const data = resp.data
  if (data && data.time) {
	window.timeDifference = Date.now() - data.time
  }
  if (data) {
	data.config = resp.config
  }
  Event.$emit('ajax-complete', { url: resp.config.url, _id: resp.config._id })
  window.toast && window.toast.hide()
  return resp
}, error => {
  const resp = error.response
  if (resp) {
	Event.$emit('ajax-complete', { url: resp.config.url, _id: resp.config._id })
	console.log(error)
  }
  return error
})
axios.interceptors.request.use(config => {
  const diff = window.timeDifference || 0
  if (!config.url.match(/^(http:\/\/)|(https:\/\/)/)) {
	config.url = environment.getDomain() + config.url
  }
  const userType = window.localStorage.getItem('user-type')
  const site = getSessionStorage('site'), user = getSessionStorage(userType)
  console.log(userType)
  const now = (config.now || Date.now()) - diff
  config.headers.timestamp = now
  if (site.tokenSuffix && user.tokenKey) {
	const key = CryptoJS.MD5(now + site.tokenSuffix).toString().toUpperCase().substr(0, 14) + '=='
	config.headers.token = encrypt(user.tokenKey, '000' + now, key)
	config.headers.tokenType = 'wx'
  }

  requestId++
  config._id = requestId
  Event.$emit('ajax-loading', { url: config.url, _id: config._id })
  return config
}, error => {
  window.toast.hide()
  Event.$emit('ajax-complete', error)
  return Promise.reject(error)
})

let layerMsgId = 0

class Page {
  constructor (opts) {
	this.list = []
	this.totalPageCount = 0
	this.totalCount = 0
	this.pageNo = 1
	this.pageSize = 20
	if (opts) {
	  Object.assign(this, opts)
	}
  }

  stringify () {
	return 'pageSize=' + this.pageSize + '&pageNo=' + this.pageNo
  }
}

function checkResponse (resp, that) {
  if (resp.data.state === 'success') {
	that.$toast.success('操作成功')
	return resp
  } else {
	const msg = MsConfig.errorMessage[resp.data.message] || resp.data.message
	return that.alert(msg, 'Error').then(nvl => Promise.reject(resp.data.message))
  }
}

export default {
  aesKey: '',
  domain: environment.getDomain(),
  request: axios,
  event: Event,
  $layer: null,
  routerBack: false,
  imageServer: environment.getImageServer(),
  environment: environment.get(),
  httpData: httpData,
  SUCCESS_STATE: 'success',
  securityChecked: false, // 是否检查过配置
  decrypted (encodeText, vi, key) {
	return decrypted(encodeText, vi, key)
  },
  encrypt (text, vi, key) {
	return encrypt(text, vi, key)
  },
  createPage (opts) {
	return new Page(opts)
  },
  /**
   * 登录信息
   */
  login (account, password) {
	const params = { account: account, password: password }
	const api = this
	return axios.post('/api/login', params).then(resp => {
	  const data = resp.data
	  if (data.state === 'success') {
		data.result.tokenKey = api.decrypted(data.result.tokenKey, '000' + data.time, api.aesKey)
	  }
	  return resp
	})
  },
  loginOut () {
	return false
  },
  /**
   * 获取站点信息
   */
  websiteInfo () {
	const $this = this
	return axios.get($this.environment.cluster + '/api/site/info').then(resp => {
	  const result = resp.data.result
	  $this.aesKey = result.decrypt
	  document.title = result.shortName
	  return resp
	})
  },
  deepAssign (target, src) {
	for (const key in src) {
	  if (typeof (src[key]) === 'object') {
		this.deepAssign(target[key] || {}, src[key])
	  } else {
		target[key] = src[key]
	  }
	}
  },
  delay (time) {
	return new Promise((resolve, reject) => {
	  setTimeout(() => resolve(time), time)
	})
  },
  delayRandom (second) {
	return this.delay((Math.random() * second * 1000).toFixed())
  },
  imagePath (img) {
	if (!img) {
	  return img
	} else if (img.match(/http:\/\/|https:\/\//)) {
	  return img
	} else {
	  return this.environment.imageServer + img
	}
  },
  isWechatBrowser () {
	return navigator.userAgent.match(/MicroMessenger/i)
  },
  isApple () {
	return /iphone/i.test(window.navigator.userAgent)
  },
  isEmpty (str) {
	if (str === null || str === undefined || str === '') {
	  return true
	} else {
	  return false
	}
  },
  wechatLogin (type) {
	const path = location.hash.replace('#/', '/')
	window.localStorage.setItem('wechat-redirect-hash', path)
	window.localStorage.setItem('user-type', type)
	const url = this.environment.domain + '/api/wechat/redirect_wechat?redirect=' + encodeURIComponent(location.origin + '/#/wechat-auth/')
	window.location.replace(url)
	return false
  },
  wechatRegister () {
	const url = this.environment.domain + '/api/wechat/redirect_wechat?redirect=' + encodeURIComponent(location.origin + '/#/register/')
	window.location.replace(url)
	return false
  },
  format (date, fmt) {
	if (!(date instanceof Date)) {
	  date = new Date(date)
	}
	const o = {
	  'M+': date.getMonth() + 1, 'd+': date.getDate(), 'h+': date.getHours(), 'm+': date.getMinutes(), 's+': date.getSeconds(), 'q+': Math.floor((date.getMonth() + 3) / 3), S: date.getMilliseconds()
	}
	if (/(y+)/.test(fmt)) {
	  fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	for (const k in o) {
	  if (new RegExp('(' + k + ')').test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
	  }
	}
	return fmt
  },
  async postJsonBody (url, obj, check) {
	return axios.post(url, obj)
				.then(resp => {
				  if (check) {
					return checkResponse(resp, this)
				  }
				  return resp
				})
  },
  async httpPost (url, params, check) {
	return axios.post(url, qs.stringify(params))
				.then(resp => {
				  if (check) {
					return checkResponse(resp, this)
				  }
				  return resp
				})
  },
  async httpPostJson (url, formData) {
    const headers = { 'Content-Type': 'application/json' }
    return this.request({ method: 'post', url: url, data: formData, headers })
  },
  async httpUpload (url, formData) {
	const headers = { 'Content-Type': 'multipart/form-data' }
	return this.request({ method: 'post', url: url, data: formData, headers })
  },
  async httpGet (url, params) {
	if (params) {
	  const param = qs.stringify(params)
	  if (param.trim() !== '') {
		url += url.indexOf('?') > 0 ? '&' : '?'
		url += param
	  }
	}
	return axios.get(url, params)
  },
  validateAll (vue, dictionary) {
	return vue.$validator.validateAll().then(result => {
	  if (!result) {
		const error = new Error(vue.errors.items[0].msg)
		if (!dictionary) {
		  error.validateError = vue.errors.items[0]
		  return Promise.reject(error)
		} else {
		  const it = vue.errors.items[0]
		  const msg = dictionary.rules[it.rule] + dictionary.attrs[it.field]
		  this.msg(msg)
		  const dom = document.querySelector('[name="' + it.field + '"]')
		  if (dom) {
			dom.focus()
		  }
		  return Promise.reject(msg)
		}
	  }
	})
  },
  showValidateError (e, dictionary) {
	const it = e.validateError
	if (it) {
	  const msg = dictionary.rules[it.rule] + dictionary.attrs[it.field]
	  this.msg(msg).then(nvl => {
		const dom = document.querySelector('[name="' + it.field + '"]')
		if (dom) {
		  dom.focus()
		}
	  })
	}
  },
  queryParam (name) {
	const tmp = location.href.split('?')
	if (tmp.length === 1) {
	  return null
	}
	return tmp[1].split('&').map(t => {
	  const param = t.split('=')
	  return { name: param[0], value: decodeURIComponent(param[1]) }
	}).find(t => t.name === name)
  }, // 格式化GET参数
  qsStringify (uri, params) {
	const type = typeof uri
	if (type === 'object') {
	  return qs.stringify(uri)
	} else if (typeof params === 'object' && type === 'string') {
	  return uri + '?' + qs.stringify(params)
	}
  },
  alert (message, alertType) {
	const $this = this
	const opts = { optType: 'alert', alertType }
	return new Promise((resolve, reject) => {
	  $this.$layer.alert(message, opts, resolve)
	})
  },
  confirm (title, content) {
	if (!content) {
	  [title, content] = ['提示', title]
	}
	const opts = { optType: 'confirm', icon: 'aui-icon-question', title }
	return new Promise((resolve, reject) => {
	  this.$layer.confirm(content, opts, resolve, reject)
	})
  },
  async msg (content, time) {
	time = time || 2
	this.$layer.close(layerMsgId)
	layerMsgId = this.$layer.msg(content, { optType: 'msg', time })
  },
  async toast (type, title, duration) {
	if (type === 1) {
	  window.toast.success({ title, duration })
	}
	return this.delay(duration)
  },
  inputBlur () {
	window.scrollTo(0, 0)
  },
  ajaxGet (url) {
	const ajax = new XMLHttpRequest()
	return new Promise((resolve, reject) => {
	  ajax.onreadystatechange = () => {
		if (ajax.readyState === 4) {
		  if (ajax.status === 200) {
			return resolve(ajax.responseText.trim())
		  } else {
			return reject(new Error('数据获取错误'))
		  }
		}
	  }
	  ajax.open('GET', url, true) // true异步请求，false同步
	  ajax.send(null)
	})
  },
  getSecurity () {
	if (this.securityChecked) {
	  return Promise.resolve()
	} else {
	  return this.$store.dispatch('comm.initSite').then(nvl => this.securityChecked = true)
	}
  },
  perfectImgUrl (imgUrl) {
	return this.imagePath(imgUrl)
  }
}
