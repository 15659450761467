import utils from '../../js/utils'

// 员工管理员菜单
const menus = [
  { name: '房态管理', code: 'roomState', link: '/ms/room-state', usable: false, icon: 'icon-ico_qiantaijiedai_fangjianyupai menu-icon-red', imgName: 'ftgl.png' },
  { name: '入住管理', code: 'checkin', link: '/ms/checkin', usable: false, icon: 'icon-fangjianweihu menu-icon-blue', imgName: 'rzgl.png' },
  { name: '财务管理', code: 'finance', link: '/ms/finance', usable: false, icon: 'icon-caiwu menu-icon-org', imgName: 'cwgl.png' },
  { name: '信息审核', code: 'checkin', link: '/ms/customer/customer-order-info-list', usable: false, icon: 'icon-chakan menu-icon-gree', imgName: 'xxsh.png' },
  { name: '退房审核', code: 'checkout', link: '/ms/customer/checkout-list', usable: false, icon: 'icon-tuichu menu-icon-skyblue', imgName: 'tfsh.png' },
  { name: '保洁管理', code: 'clean', link: '/ms/room-task', usable: false, icon: 'icon-qingli-copy menu-icon-gree', imgName: 'bjgl.png' },
  { name: '联系客服', code: 'cleanTask', link: '/article/3', usable: false, icon: 'icon-xiaoxi menu-icon-blue', imgName: 'lxkf.png' },
  { name: '临时开锁', code: 'applyLock', link: '/ms/apply-lock', usable: false, icon: 'icon-lock  menu-icon-LightSeaGreen', imgName: 'lsks.png' },
  {
    name: '更多服务', code: 'setting', link: '/ms/setting', usable: false, icon: 'icon-et-more1 menu-icon-skyblue', imgName: 'gdfw.png',
    children: [
      { name: '设备管理', code: 'device', link: '/ms/equipment/roomlist', usable: false, icon: 'icon-lock  menu-icon-LightSeaGreen' },
      { name: '房价管理', code: 'roomPrice', link: '', usable: false, icon: 'icon-ico_shujuchaxunyutongji_fangjianfujiazhuangtaichaxun' },
      { name: '财务明细', code: 'finance', link: '/ms/in-exp-order-list', usable: false, icon: 'icon-caiwu' },
      { name: '财务报表', code: 'finance', link: '', usable: false, icon: 'icon-caiwufenxi' },
      { name: '员工管理', code: 'employee', link: '/ms/employee-list', usable: false, icon: 'icon-user' },
      { name: '房号管理', code: 'roomNo', link: '/ms/room-list', usable: false, icon: 'icon-wodefangjian' },
      { name: '平台管理', code: 'platform', link: '/ms/company-platform', usable: false, icon: 'icon-pingtaishouquan' },
      { name: '房源管理', code: 'source', link: '', usable: false, icon: 'icon-data-connection' },
      { name: '操作日志', code: 'opLog', link: '/ms/log-info', usable: false, icon: 'icon-zhuanxierizhi' },
      { name: '消息管理', code: 'cleanTask', link: '/ms/msg', usable: false, icon: 'icon-xiaoxi' },
      { name: '公司设置', code: 'setting', link: '/ms/setting', usable: false, icon: 'icon-setting menu-icon-skyblue' },
      { name: '疫情防控', code: 'yiqing', link: '/ms/yiqing', usable: false, icon: 'icon-chakan  menu-icon-DarkRed' },
      { name: '收入支出', code: 'finance', link: '/ms/income', usable: false, icon: 'icon-setting' },
      { name: '信息管理', code: 'customerOrderInfo', link: '/ms/customer/customer-order-info-list', usable: false, icon: 'icon-setting' },
      { name: '退房管理', code: 'checkout', link: '/ms/customer/checkout-list', usable: false, icon: 'icon-setting' }
    ]
  }
]

// 业主菜单
const houseOwnerMenus = [
  { name: '房态管理', code: 'roomState', link: '/ms/room-state', usable: true, icon: 'icon-ico_qiantaijiedai_fangjianyupai menu-icon-red', imgName: 'ftgl.png' },
  { name: '查看订单', code: 'roomState', link: '/ms/order-list', usable: false, icon: 'icon-ico_qiantaijiedai_fangjianyupai menu-icon-red', imgName: 'ftgl.png' },
  { name: '开门日志', code: 'roomState', link: '/ms/lock-log', usable: true, icon: 'icon-ico_qiantaijiedai_fangjianyupai menu-icon-red', imgName: 'tfsh.png' },
  { name: '入住管理', code: 'setting', link: '/ms/checkin', usable: false, icon: 'icon-fangjianweihu menu-icon-blue' },
  { name: '财务报表', code: 'finance', link: '/ms/finance', usable: true, icon: 'icon-caiwufenxi', imgName: 'cwgl.png' }
]

const state = {
  menus, restricts: {}, version: 0, role: '', paths: utils.cloneData(menus)
}

const getters = {}

const mutation = {
  FUNCTIONS_INIT: 'FUNCTIONS_INIT'
}

const actions = {
  'functions.init' ({ commit }, fun) {
    commit(mutation.FUNCTIONS_INIT, fun)
  }
}

const mutations = {
  [mutation.FUNCTIONS_INIT] (state, fun) {
    state.role = fun.role
    if (fun.role && fun.role.match(/storekeeper|manager/)) {
      const menus_ = utils.cloneData(menus)
      menus_.forEach(menu => menu.usable = true)
      state.paths.forEach(path => path.usable = true)
      state.menus = menus_
    } else if (fun.role === 'investor') {
      state.paths = state.menus = utils.cloneData(houseOwnerMenus)
      state.menus = state.menus.filter(menu => menu.usable)
      // state.paths = utils.cloneData(houseOwnerMenus)
    } else {
      console.log('fun.functions', JSON.stringify(fun.functions))
      if (fun.functions) {
        fun.functions.setting = true
        const menus_ = utils.cloneData(menus)
        for (const name in fun.functions) {
          const menus = menus_.filter(menu => menu.code === name)
          if (menus && menus.length > 0) {
            menus.forEach(menu => menu.usable = fun.functions[name] === true)
          }
          const paths = state.paths.filter(path => path.code === name)
          if (paths && paths.length > 0) {
            paths.forEach(path => path.usable = fun.functions[name] === true)
          }
        }
        state.menus = menus_.filter(menu => menu.usable)
      }
    }
    state.version = fun.version || state.version
    state.restricts = fun.restricts || {}
  }
}

export default {
  state, actions, getters, mutations
}
