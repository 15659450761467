<template>
  <div>
	<layer v-model="show" styles="width:100%;bottom:-.1px" skin="footer" anim="up" @close="cancel">
	  <div class="win">
		<div class="win-title text-center">{{ title }}</div>
		<div class="multi-select" v-if="!selectModel">
		  <span class="select-tag" v-for="(type,index) in list" :class="{'on':selectVal===type[listKey]}" :key="index" @click="click(type)">{{ type.name }}</span>
		</div>
		<div v-else class="bottom-menu" :style="'max-height:'+menuBodyHeight+'px'">
		  <ul class="aui-list aui-form-list bottom-menu-ul bottom-menu-ul-select ">
			<li class="aui-list-item" v-for="(type,index) in list" :key="index" @click="click(type)" :class="[{'selected':selectVal===type[listKey]},{'li-disabled':type.$selectDisabled}]">
			  <div class="aui-list-item-inner">
				<div class="aui-list-item-input aui-font-size-14">
				  {{ listLabel ? type[listLabel] : type.name }} <s class="aui-font-size-12" style="color:#9a9a9a" v-if="type.remark">{{ type.remark }}</s>
				</div>
				<div class="aui-list-item-label-icon">
				  <i v-if="selectVal===type[listKey]" class="iconfont icon-select-bold aui-font-size-20"/>
				</div>
			  </div>
			</li>
		  </ul>
		</div>
		<div class="btn-area" v-if="showBtn">
		  <div class="btn" @click="cancel">取消</div>
		  <div class="btn btn-confirm" @click="confirm">确认</div>
		</div>
	  </div>
	</layer>
  </div>
</template>
<style scoped>
li.selected {
  background-color: #f6f2de
}

li.li-disabled {
  color: #a19e9ed1
}
</style>
<script>
import Layer from '../layer/layer.vue'

export default {
  components: { Layer },
  props: {
	title: { type: String, default: '' },
	val: {},
	showBtn: { type: Boolean, default: true },
	selectModel: { type: Boolean, default: false },
	value: { type: Object },
	listKey: { type: String, default: '' },
	listLabel: { type: String, default: '' },
	list: { type: null, default: [] },
	menuBodyHeight: { type: Number, default: 290 },
	// show: { type: Boolean, default: true },
	showTitle: { type: Boolean, default: true },
	single: { type: Boolean, default: true },
	anim: { type: String, default: 'up' }
  },
  data () {
	return {
	  visible: false, selected: [], selectVal: '', show: true
	}
  },
  mounted () {
	if (this.val) {
	  this.selectVal = this.val
	}
  },
  methods: {
	click (type) {
	  if (!type.$selectDisabled) {
		if (this.single) {
		  this.$api.delay(100).then(nvl => this.$emit('selected', type))
		  this.selectVal = type[this.listKey]
		}
	  }
	},
	confirm () {
	  if (this.single) {
		this.$emit('confirm', this.selectVal)
	  }
	  this.cancel()
	},
	cancel () {
	  // eslint-disable-next-line vue/no-mutating-props
	  this.value.show = false
	  // eslint-disable-next-line vue/no-mutating-props
	  this.value.action = ''
	}
  }
}
</script>
