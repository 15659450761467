import api from '../../js/api'
import CompanyConfig from '../../js/company.config'

function getSessionStorage (key, def) {
  const json = window.sessionStorage.getItem(key)
  if (json) {
	const object = JSON.parse(json)
	return object
  } else if (def) {
	return def
  } else {
	return {}
  }
}

function getCompanyConfig () {
  const json = window.sessionStorage.getItem('company-config')
  const object = JSON.parse(json)
  return new CompanyConfig(object)
}

function getLocalStorage (key, def) {
  let str = window.localStorage.getItem(key)
  if (str && str.match(/{.*}/)) {
	str = JSON.parse(str)
  }
  return str || def
}

const state = {
  site: getSessionStorage('site'),
  company: getSessionStorage('company', { id: 1, name: '', num: '100001' }),
  companyConfig: getCompanyConfig(),
  user: getSessionStorage('user'),
  customer: getSessionStorage('customer'),
  cleaner: getSessionStorage('cleaner'),
  loginInfo: getLocalStorage('loginInfo', { account: '', password: '' }),
  delay: 0,
  taskBarShow: false,
  notificationBarShow: false,
  menuMin: false
}

const getters = {
  getDelay: state => state.delay,
  getUser: state => state.user,
  getCustomer: state => state.customer,
  getSite: state => state.site,
  getCompany: state => state.company,
  getLoginInfo: state => state.loginInfo
}

const mutation = {
  COMM_INIT_SUCCESS: 'COMM_INIT_SUCCESS',
  COMM_INIT_USER_SUCCESS: 'COMM_INIT_USER_SUCCESS',
  COMM_INIT_CUSTOMER_SUCCESS: 'COMM_INIT_CUSTOMER_SUCCESS',
  // # 退出登录
  COMM_LOGOUT_SUCCESS: 'COMM_LOGOUT_SUCCESS',
  COMM_LEFT_MENU_INIT: 'COMM_LEFT_MENU_INIT',
  COMM_LEFT_MENU_ACTIVE: 'COMM_LEFT_MENU_ACTIVE',
  COMM_LEFT_MENU_ITEM: 'COMM_LEFT_MENU_ITEM',
  SET_LOGIN_INFO: 'SET_LOGIN_INFO',
  COMPANY_CONFIG_SETTING: 'COMPANY_CONFIG_SETTING',
  COMPANY_NAME_SETTING: 'COMPANY_NAME_SETTING',
  //
  COMPANY_CONFIG_SET_DIC: 'COMPANY_CONFIG_SET_DIC'
}

const actions = {
  'comm.initSite' ({ commit }, flag) {
	return api.websiteInfo().then(resp => {
	  commit(mutation.COMM_INIT_SUCCESS, resp.data.result)
	})
  },
  'comm.set.login.info' ({ commit }, login) {
	commit(mutation.SET_LOGIN_INFO, login)
  },
  'comm.setUserInfo' ({ commit }, user) {
	return new Promise(resolve => {
	  commit(mutation.COMM_INIT_USER_SUCCESS, { user, resolve })
	})
  },
  'ms.setCustomerInfo' ({ commit }, customer) {
	commit(mutation.COMM_INIT_CUSTOMER_SUCCESS, customer)
  },
  'comm.logout' ({ commit }) {
	commit(mutation.COMM_LOGOUT_SUCCESS)
  },
  'company.name.setting' ({ commit }, name) {
	commit(mutation.COMPANY_NAME_SETTING, name)
  },
  'company.config.setting' ({ commit }, config) {
	commit(mutation.COMPANY_CONFIG_SETTING, config)
  },
  'company.config.set.dic' ({ commit }, dic) {
	commit(mutation.COMPANY_CONFIG_SET_DIC, dic)
  }
}

const mutations = {
  [mutation.COMM_INIT_SUCCESS] (state, site) {
	state.site = site
	state.site.complete = true
	window.__tokenSuffix = site.tokenSuffix
	sessionStorage.setItem('site', JSON.stringify(site))
  },
  [mutation.COMM_LOGOUT_SUCCESS] (state) {
	sessionStorage.clear()
	state.site = {}
	state.user = {}
  },
  [mutation.COMM_INIT_USER_SUCCESS] (state, opts) {
	const user = opts.user
	Object.assign(state.user, user)
	state.user.complete = true
	state.company = user.company
	// if (user.company) {
	//   user.company.num = '1'
	//   while ((user.company.num + user.company.id).length < 6) {
	//     user.company.num += '0'
	//   }
	//   user.company.num += user.company.id
	// }
	sessionStorage.setItem('company', JSON.stringify(state.company))
	sessionStorage.setItem('user', JSON.stringify(state.user))
	opts.resolve()
  },
  [mutation.COMM_INIT_CUSTOMER_SUCCESS] (state, customer) {
	Object.assign(state.customer, customer)
	state.customer.complete = true
	sessionStorage.setItem('customer', JSON.stringify(state.customer))
  },
  [mutation.SET_LOGIN_INFO] (state, loginInfo) {
	state.loginInfo = loginInfo
	localStorage.setItem('loginInfo', JSON.stringify(loginInfo))
  },
  [mutation.COMPANY_NAME_SETTING] (state, name) {
	state.company.name = name
	sessionStorage.setItem('company', JSON.stringify(state.company))
  },
  [mutation.COMPANY_CONFIG_SETTING] (state, config) {
	state.companyConfig = new CompanyConfig(config)
	sessionStorage.setItem('company-config', JSON.stringify(config))
  },
  [mutation.COMPANY_CONFIG_SET_DIC] (state, dics) {
	dics.forEach(dic => {
	  if (dic.action === 'add') {
		state.companyConfig[dic.typeName].push({ type: 'USER', name: dic.name, value: dic.value, code: dic.code })
	  } else if (dic.action === 'edit') {
		const index = state.companyConfig[dic.typeName].findIndex(dic1 => (dic1.name === dic.original && dic1.type === 'USER'))
		if (index >= 0) {
		  state.companyConfig[dic.typeName].splice(index, 1)
		}
		state.companyConfig[dic.typeName].push({ type: 'USER', name: dic.name, value: dic.value, code: dic.code })
	  } else {
		const index = state.companyConfig[dic.typeName].findIndex(dic1 => (dic1.name === dic.name && dic1.type === 'USER'))
		if (index >= 0) {
		  state.companyConfig[dic.typeName].splice(index, 1)
		}
	  }
	})
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
