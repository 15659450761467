<template>
  <header class="aui-bar aui-bar-nav" :class="classNames" :style="{position:fixed?'fixed':'absolute'}">
	<a v-if="back" class="aui-pull-left aui-btn" @click="backTo">
	  <span v-if="hasBackArrow" class="aui-iconfont aui-icon-left flex-line-height"></span>
	</a>
	<div v-if="!hasSlot" class="aui-title flex-line-height">{{ title }}</div>
	<slot></slot>
  </header>
</template>
<script type="text/javascript">
export default {
  props: {
	title: { type: String, default: '' },
	fixed: { type: Boolean, default: true },
	back: { type: Boolean, default: true },
	hasBackArrow: { type: Boolean, default: true },
	isRouter: { type: Boolean, default: true },
	backLink: { type: String, default: '/' },
	hasSlot: { type: Boolean, default: false },
	replaceLink: { type: Boolean, default: false },
	classNames: { type: String, default: '' }
  },
  name: 'NavHeader',
  created () {
  },
  methods: {
	backTo () {
	  if (this.isRouter) {
		if (this.replaceLink) {
		  this.$router.replace(this.backLink)
		} else {
		  this.$router.push(this.backLink)
		}
		// (this.replaceLink ? this.$router.replace : this.$router.push)(this.backLink)
	  } else {
		this.$emit('back', 'back')
	  }
	}
  }
}
</script>
<style scoped>
.aui-title {
  font-size: .85rem;
  height: 48px;
  line-height: 48px;
}

.aui-bar, .aui-bar-nav .aui-btn {
  height: 48px;
  line-height: 48px;
  vertical-align: middle;
  /*overflow: hidden;*/
}

header.aui-bar.checked {
  background: #fa6800;
}

header.aui-bar.checked .aui-pull-left.aui-btn {
  display: none;
}
</style>
