import api from './api.js'
import MsConfig from './ms.config'

const fmt = 'yyyy-MM-dd hh:mm:ss'

class DataHttpApi {
  // 商城信息
  storeInfo () {
    return api.httpGet('/api/site/store/info').then(resp => resp.data.result)
  }

  // 首页统计
  todayTotal () {
    return api.httpGet('/api/ms/company/today-total').then(resp => resp.data.result)
  }

  // 订单详情
  orderDetail (orderNum) {
    return api.httpPost('/api/ms/order/detail', { orderNum })
  }

  orderShareDetail (id) {
    return api.httpPost('/api/ms/room-share/detail', { id })
  }

  orderShareSave (id, params) {
    return api.postJsonBody('/api/ms/room-share/save?id=' + id, params).then(resp => resp.data.result)
  }

  orderSearch (page, params) {
    const uri = '/api/ms/order/search?' + page.stringify()
    return api.postJsonBody(uri, params).then(resp => resp.data.result)
  }

  // 修改订单
  orderModify (order) {
    return api.postJsonBody('/api/ms/order/modify', order, true)
  }

  // 删除订单
  orderDelete (orderNum) {
    return api.httpPost('/api/ms/order/delete', { orderNum }, true)
  }

  orderInExps (orderId) {
    return api.httpPost('/api/ms/income/order-list', { orderId })
  }

  rooms (all) {
    const params = {}
    if (all) {
      params.all = 'y'
    }
    return api.httpPost('/api/ms/room/list', params).then(resp => {
      return resp.data.result
    })
  }

  ownRooms (usable) {
    return api.httpPost('/api/ms/room/own').then(resp => {
      if (usable) {
        resp.data.result = resp.data.result.filter(room => !room.deleteFlag)
      }
      return resp
    })
  }

  // 取消排房原因
  roomStatePlanCancelReason () {
    return api.httpGet('/api/ms/company/dic/order_cancel_reason')
  }

  // 取消排房
  roomStateCancelPlan (id, reason) {
    return api.httpPost('/api/ms/room-state/cancel-plan', { id, reason }, true)
  }

  // 房态详情
  roomStateDetail (id) {
    return api.httpPost('/api/ms/room-state/detail', { id })
  }

  // 排房
  roomStatePlan (params) {
    return api.postJsonBody('/api/ms/room-state/plan-state', params, true)
  }

  // 检查房间状态是否存在冲突
  roomStateCheckCollide (id, roomId, start, end) {
    if (typeof start !== 'string') {
      start = api.format(start, fmt)
    }
    if (typeof end !== 'string') {
      end = api.format(end, fmt)
    }
    return api.httpPost('/api/ms/room-state/check-collide', { id, roomId, start, end }).then(resp => resp.data.result)
  }

  // 换房
  roomStateChangeRoom (params) {
    return api.postJsonBody('/api/ms/order/change-state', params, true)
  }

  // 锁房
  roomStateLock (params) {
    return api.postJsonBody('/api/ms/room-state/lock', params, true)
  }

  // 取消锁定
  roomStateUnlock (id) {
    return api.httpPost('/api/ms/room-state/unlock', { id }, true)
  }

  roomStateSearch (params) {
    return api.httpPost('/api/ms/room-state/search', params)
  }

  // 提交房态到入驻状态
  roomStateCheckin (id) {
    return api.httpPost('/api/ms/room-state/checkin', { id }, true)
  }

  // 提交房态到退房状态
  roomStateCheckout (id) {
    return api.httpPost('/api/ms/room-state/checkout', { id }, true)
  }

  // 取消退房状态
  roomStateCancelCheckout (id) {
    return api.httpPost('/api/ms/room-state/cancel-checkout', { id }, true)
  }

  // 检查续住的天数
  roomStateExtendsCheck (id) {
    return api.httpPost('/api/ms/room-state/room-extends-info', { id })
  }

  // 房子在时段的占用情况
  roomStateTimeUsed (start, end, state) {
    const params = {
      start: api.format(start, fmt), end: api.format(end, fmt)
    }
    const uri = '/api/ms/room-state/search-room?' + api.qsStringify(params)
    return api.postJsonBody(uri, state)
  }

  // 提交续住
  roomStateExtends (id, params) {
    return api.postJsonBody('/api/ms/room-state/extends?id=' + id, params, true)
  }

  // 保洁任务查询roomStateCheckout
  roomStateCleanTask (params) {
    return api.httpPost('/api/ms/room/task/list', params)
  }

  roomStateCleanTaskInfo (id) {
    return api.httpPost('/api/ms/room/task/info', { id })
  }

  roomStateCleanTaskSave (task, worker) {
    let uri = '/api/ms/room/task/clean-save'
    uri += worker ? `?workerId=${worker}` : ''
    return api.postJsonBody(uri, task, true)
  }

  roomStateCleanTaskSetStatus (stateId, status) {
    return api.httpPost('/api/ms/room/task/set-status', { stateId, status }, true)
  }

  roomClean (id, cleaned) {
    return api.httpPost('/api/ms/room/clean', { id, clean: cleaned === 'cleaned' }, true)
  }

  roomRepair (id, repair) {
    return api.httpPost('/api/ms/room/repair', { id, repair: repair === 'repair' }, true)
  }

  cleanerList () {
    return api.httpPost('/api/ms/company/worker/cleaner-list')
  }

  cleanerInfoSave (params) {
    return api.postJsonBody('/api/ms/company/worker/save-cleaner', params, true)
  }

  cleanTaskSearch (params) {
    return api.postJsonBody('/api/ms/room/task/search', params)
  }

  cleanTaskStatistic (params) {
    return api.postJsonBody('/api/ms/room/task/statistic', params)
  }

  cleanRangeSetting (range) {
    return api.httpPost('/api/ms/company/config/clean-time-range', { range }, true)
  }

  // 新建订单
  newOrder (order) {
    return api.postJsonBody('/api/ms/order/new', order, true)
  }

  // 收支查询
  inExpSearch (params, inExp) {
    const uri = '/api/ms/income/search-list?' + api.qsStringify(params, true)
    return api.postJsonBody(uri, inExp)
  }

  dayInExpSearch (params, inExp) {
    return api.postJsonBody('/api/ms/day-income-exp/search-list?' + api.qsStringify(params, true), inExp)
  }

  // 删除收支
  deleteInExp (id) {
    return api.httpPost('/api/ms/income/delete', { id }).then(resp => {
      return resp.data.result
    })
  }

  // 收支信息
  inExpInfo (id) {
    return api.httpPost('/api/ms/income/edit', { id })
  }

  // 保存收支
  saveInExp (inExp) {
    return api.postJsonBody('/api/ms/income/new', inExp, true)
  }

  workerInfo (id) {
    return api.httpPost('/api/ms/company/worker/info', { id })
  }

  workerInfoSave (employee) {
    return api.postJsonBody('/api/ms/company/worker/save', employee, true)
  }

  workerList () {
    return api.httpPost('/api/ms/company/worker/list')
  }

  /**
   * 绑定员工
   */
  workerBinding (id) {
    return api.httpPost('/api/ms/company/worker/wechat-binding', { id })
  }

  workerUnBinding (id) {
    return api.httpPost('/api/ms/company/worker/wechat-unbinding', { id })
  }

  /**
   * 绑定店主
   * @param id
   * @returns {Promise<* | AxiosResponse<any>>}
   */
  bindingStorekeeper (id) {
    return api.httpPost('/api/ms/company/worker/wechat-binding-storekeeper', { id }, true)
  }

  roomFunctionConfig (id) {
    return api.httpGet('/api/ms/room-function/config', { id })
  }

  // WECHAT token validate
  validateToken (token, userType) {
    const type = userType || localStorage.getItem('user-type')
    const url = '/api/wechat-user/' + (type === MsConfig.userType.user ? 'ms/' : '') + 'token-validate'
    return api.httpPost(url, { token }).then(resp => {
      const data = resp.data
      if (data.state !== 'success') {
        // return vue.$router.replace('/welcome').then(r => Promise.reject(data.code))
        return Promise.reject(data.code)
      } else {
        return data.result
      }
    })
  }

  /**
   * 员工本地权限信息和服务器信息对比,如果业务版本不一致,调用functions.init
   */
  workerVersion () {
    window.toast.loading({})
    const version = api.$store.state.fun.version
    return api.httpGet('/api/ms/company/worker/version?version=' + version).then(resp => {
      const result = resp.data.result
      if (version === result.version) {
        return result
      }
      if (!result.role || result.role === '') {
        return api.$router.replace('/welcome').then(nvl => Promise.reject(new Error('not roles')))
      }
      if (result.role === 'cleaner') {
        api.$router.replace('/cleaner/index').then()
      }
      return api.$store.dispatch('functions.init', result).then(nvl => result)
    })
  }

  sysAdvSpace (name) {
    return api.httpGet('/api/ms/adv/space/' + name).then(resp => {
      if (resp.data.state === 'success') {
        return resp.data.result.map(item => api.imagePath(item.httpPath))
      } else {
        return []
      }
    })
  }

  cleanerTaskInfo (taskId) {
    return api.httpPost('/api/cleaner/task/info', { taskId })
  }

  cleanerTaskSetting (taskId, status) {
    return api.httpPost('/api/cleaner/task/set-status', { taskId, status }, true)
  }
}

const dataHttpApi = new DataHttpApi()
export default dataHttpApi
