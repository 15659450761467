<template>
  <div class="pos-relative zero-list" style="height: 400px">
    <div class="zero-list-body">
      <i class="iconfont icon-coffee1"></i>
      <div>{{title}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: { String, default: '暂时没有数据' }
    }
  }
</script>
