<template>
  <section>
    <transition :name="transitionName">
      <router-view class="main"/>
    </transition>
  </section>
</template>

<script>

  export default {
    name: 'routerVue',
    data () {
      return {
        transitionName: ''
      }
    },
    watch: {
      '$route' (to, from) {
        //  监听路由变化时的状态为前进还是后退
        if (to.meta || from.meta) {
          if (to.meta.index || from.meta.index) {
            const isBack = to.meta.index > (from.meta.index | 0)
            // console.log(to.meta.index, isBack)
            if (isBack) {
              this.transitionName = 'slide-right'
            } else {
              this.transitionName = 'slide-left'
            }
          }
        }
      }
    }
  }
</script>

<style scoped>
  .main {
    position: absolute;
    height: calc(100vh);
    top: 0;
    width: 100%
  }
</style>
<style lang="less" scoped>
  .slide-right-enter-active, .slide-right-leave-active, .slide-left-enter-active, .slide-left-leave-active {
    will-change: transform;
    transition: all 500ms;
    position: absolute;
  }

  /* 右滑初始装状态*/
  .slide-right-enter {
    transform: translate3d(-100%, 0, 0);
  }

  /* 右滑执行状态*/
  .slide-right-leave-active {
    transform: translate3d(100%, 0, 0);
  }

  /* 右滑最终状态*/
  .slide-right-enter-to {
    transform: translate3d(0, 0, 0);
  }

  /* 左滑初始装状态*/
  .slide-left-enter {
    transform: translate3d(100%, 0, 0);
  }

  /* 左滑执行状态*/
  .slide-left-leave-active {
    transform: translate3d(-100%, 0, 0);
  }

  /* 左滑最终状态*/
  .slide-right-enter-to {
    transform: translate3d(0, 0, 0);
  }
</style>
