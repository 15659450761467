<template>
  <div>
    <transition name="nav">
      <div class="m-navba" v-if="visible" :style="styles">
        <slot></slot>
        <router-view/>
      </div>
    </transition>
    <div class="m-navbar" v-if="visible" @click="close"></div>
  </div>
</template>

<style scoped>
  /* 遮罩层 */
  .m-navbar {
    position: fixed;
    left: -3px;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 2;
    -webkit-overflow-scrolling: touch;
  }

  /* 存放弹框内容 */
  .m-navba {
    position: fixed;
    left: 100px;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    overflow: auto;
    background-color: #f5f5f5;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }

  .nav-leave {
    /* 定义 出场动画的 起始状态 */
    /* 只停留一帧 */
    transform: translateX(0px);
  }

  .nav-leave-active {
    /* 定义 出场动画 过程 */
    transition: all 0.2s ease;
  }

  .nav-leave-to {
    /* 定义 出场动画 结束状态（即：该动画要达到的目标状态） */
    transform: translateX(100%);
  }

  /* 定义  入场动画 */
  .nav-enter {
    /* 定义 入场动画 的起始状态 */
    transform: translateX(100%);
  }

  .nav-enter-active {
    /* 定义 入场动画 过程 */
    transition: all 0.3s ease;
  }

  .nav-enter-to {
    /* 定义 入场动画 过程 */
    /* 只停留一帧 */
    transform: translateX(0px);
  }
</style>

<script type="text/javascript">
  export default {
    name: 'SideNavBar',
    props: {
      value: {
        type: Boolean, default: false
      },
      styles: {
        type: String, default: 'left:100px'
      }
    },
    data () {
      return {
        visible: false
      }
    },
    methods: {
      // 点击遮罩层，关闭
      close () {
        this.visible = false
        this.$emit('close')
      }
    },
    watch: {
      value (val) {
        this.visible = val
      }
    }
  }
</script>
