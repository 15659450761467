import pako from 'pako'
import wx from 'weixin-js-sdk'
import api from './api'
import * as html2canvas from 'html2canvas'

const week = { 1: '一', 2: '二', 3: '三', 4: '四', 5: '五', 6: '六', 0: '日' }
// 一天的毫秒数
const dayMs = 60.0 * 60 * 24 * 1000
const HOUR = 60 * 60 * 1000

function checkDataType (data) {
  return Object.prototype.toString.call(data).slice(8, -1)
}

const utils = {
  wx,
  /**
   * 将二进制数据转换字符串
   */
  binaryDataToString (data) {
    const reader = new FileReader()
    reader.readAsArrayBuffer(data)
    return new Promise((resolve, reject) => {
      reader.onload = evt => {
        if (evt.target.readyState === FileReader.DONE) {
          const binData = new Uint8Array(evt.target.result)
          const head = String.fromCharCode.apply(null, new Uint16Array(binData.slice(0, 22)))
          if (head === 'content-type:text/json') {
            // let binData3 = binData.slice(30, binData.length)
            const content = pako.inflate(binData.slice(30, binData.length), {
              to: 'string'
            })
            resolve(content)
          }
        }
      }
    })
  },
  /**
   * 将字符串转换为UTF8
   */
  toUTF8 (str) {
    if (typeof str !== 'string') {
      throw new TypeError('toUTF8 function only accept a string as its parameter.')
    }
    const ret = []
    let c1, c2, c3
    let cc = 0
    for (let i = 0, l = str.length; i < l; i++) {
      cc = str.charCodeAt(i)
      if (cc > 0xffff) {
        throw new Error('InvalidCharacterError')
      }
      if (cc > 0x80) {
        if (cc < 0x07ff) {
          c1 = String.fromCharCode((cc >>> 6) | 0xc0)
          c2 = String.fromCharCode((cc & 0x3f) | 0x80)
          ret.push(c1, c2)
        } else {
          c1 = String.fromCharCode((cc >>> 12) | 0xe0)
          c2 = String.fromCharCode(((cc >>> 6) & 0x3f) | 0x80)
          c3 = String.fromCharCode((cc & 0x3f) | 0x80)
          ret.push(c1, c2, c3)
        }
      } else {
        ret.push(str[i])
      }
    }
    return ret.join('')
  },
  /***
   * Web截图,用过canves绘制dom的图片，然后压缩图片的质量
   * @returns {Promise}
   */
  webShot (div, scale) {
    // 获取dom 宽度
    const width = div.offsetWidth
    // 获取dom 高度
    const height = div.offsetHeight
    // 创建一个canvas节点
    const canvas = document.createElement('canvas')
    // 定义任意放大倍数 支持小数
    scale = scale || 1
    // 定义canvas 宽度 * 缩放
    canvas.width = width * scale
    // 定义canvas高度 *缩放
    canvas.height = height * scale
    // 获取context,设置scale
    canvas.getContext('2d').scale(scale, scale)
    const opts = {
      // 添加的scale 参数
      scale: scale, // 自定义 canvas
      canvas: canvas, // 日志开关
      logging: false, // dom 原始宽度
      width: width, // dom 原始高度
      height: height
    }
    return html2canvas(div, opts).then(canvas => {
      return Promise.resolve(canvas.toDataURL('image/jpeg', 1))
    })
  },
  /**
   * 调用微信扫描
   * @param init
   */
  async wechatScan (init) {
    const scanFunction = () => {
      return new Promise((resolve, reject) => {
        const params = {
          needResult: 1, scanType: ['qrCode'],
          success (res) {
            if (res.errMsg === 'scanQRCode:ok') {
              resolve(res.resultStr)
            } else {
              reject(new Error('扫码错误,没找到设备'))
            }
          },
          fail (err) {
            reject(err)
          }
        }
        wx.scanQRCode(params)
      })
    }
    if (init) {
      return this.wechatConfig()
                 .then(this.wechatReady)
                 .then(() => scanFunction())
    } else {
      return scanFunction()
    }
  },
  /**
   * 微信 Ready
   * @returns {Promise<unknown>}
   */
  wechatReady () {
    return new Promise((resolve, reject) => {
      wx.ready(nvl => {
        console.log('wx ready')
        return resolve(wx)
      })
    })
  },
  /**
   * 微信配置
   * @returns {Promise<void>|Promise<AxiosResponse<any>>}
   */
  wechatConfig () {
    const param = { url: window.location.href }
    if (!api.isWechatBrowser()) {
      return Promise.resolve()
    }
    return api.httpPost(api.environment.cluster + '/api/wechat/js-config', param).then(resp => {
      const signature = resp.data.result
      wx.config({
        // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        debug: false, beta: true, // 必填，公众号的唯一标识
        appId: signature.appid, // 必填，生成签名的时间戳
        timestamp: signature.timestamp, // 必填，生成签名的随机串
        nonceStr: signature.noncestr, // 必填，签名
        signature: signature.signature, // 必填，需要使用的JS接口列表
        jsApiList: ['scanQRCode', 'hideOptionMenu', 'hideMenuItems', 'updateAppMessageShareData', 'getLocation', 'openLocation'],
        openTagList: ['wx-open-launch-weapp']
      })
    })
  },
  /**
   * 获取微信 GPS 地址信息
   * @returns {Promise<unknown>}
   */
  wechatLocation () {
    return new Promise((resolve, reject) => {
      window.toast.loading({ title: '正在获取当前位置信息,请稍后' })
      wx.getLocation({
        type: 'gcj02', // 默认为 wgs84 返回 gps 坐标，gcj02 返回可用于 wx.openLocation 的坐标
        success (res) {
          window.toast.hide()
          return resolve({ lng: res.longitude, lat: res.latitude })
        }
      })
    })
  },
  analysisUrlParam (url) {
    const theRequest = Object
    if (url.indexOf('?') !== -1) {
      const str = url.split('?')
      const strs = str[1].split('&')
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1]
      }
    }
    return theRequest
  },
  /**
   * 获取上传图片的高度和宽度
   */
  imageSize (file) {
    return new Promise(resolve => {
      const image = new Image()
      image.src = file.content
      image.onload = () => {
        resolve({ width: image.width, height: image.height })
      }
    })
  },
  /**
   * 深度克隆对象
   */
  cloneData (data) {
    let result
    const dataType = checkDataType(data)
    //  通过dataType判断的类型，确定result的初始值
    switch (dataType) {
      case 'Array' :
        result = []
        break
      case 'Object' :
        result = {}
        break
      default :
        return data
    }
    for (const i in data) {
      // 循环取到每个value值
      const value = data[i]
      if (checkDataType(value) === 'Array' || checkDataType(value) === 'Object') { // 再判断数据类型
        // 递归重走
        result[i] = this.cloneData(value)
      } else {
        // 如果为基本数据类型，就直接放进新的对象或者数组
        result[i] = value
      }
    }
    return result
  },
  parseDate (date, today) {
    date.setHours(0, 0, 1, 0)
    date.week = week[date.getDay()]
    let className = ''
    if (date.getDay() > 5 || date.getDay() === 0) {
      className = 'week'
    }
    if (today && date.getTime() === today.getTime()) {
      className += ' today'
    }
    date.className = className.trim()
    date.value = date.getTime()
    return date
  },
  dayStart (date) {
    date = new Date(date)
    date.setHours(0, 0, 0, 1)
    return date
  },
  dayEnd (date) {
    date = new Date(date)
    date.setHours(23, 59, 59, 999)
    return date
  },
  addDay (date, day) {
    return new Date(date.getTime() + dayMs * day)
  },
  addHour (date, hour) {
    return new Date(date.getTime() + HOUR * hour)
  },
  monthStart (date) {
    const day = new Date(date.getTime())
    day.setDate(1)
    day.setHours(0, 0, 0, 0)
    return day
  },
  monthAdd (date, num) {
    const day = new Date(date.getTime())
    day.setMonth(day.getMonth() + num)
    return day
  },
  /**
   *   得到2个日期之间的夜晚数量
   */
  nightsBetween (start, end) {
    if ((typeof start) === 'number') {
      start = new Date(start)
    }
    if ((typeof end) === 'number') {
      end = new Date(end)
    }
    return Math.ceil((end.getTime() - start.getTime()) / dayMs)
  },
  /**
   *   2个日期的之间天数
   */
  daysBetween (start, end) {
    if ((typeof start) === 'number') {
      start = new Date(start)
    }
    if ((typeof end) === 'number') {
      end = new Date(end)
    }
    return Math.round(Math.abs((end.getTime() - start.getTime()) / dayMs))
  },
  guid () {
    const S4 = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
  },
  getPos (e) {
    let t = e.offsetTop
    let l = e.offsetLeft
    while (e) {
      t += e.offsetTop
      l += e.offsetLeft
      e = e.offsetParent
    }
    return { top: t, left: l }
  },
  getPosByParent (e, parent) {
    let t = e.offsetTop
    let l = e.offsetLeft
    while (e && e !== parent) {
      t += e.offsetTop
      l += e.offsetLeft
      e = e.offsetParent
      console.log(e)
    }
    return { top: t, left: l }
  }
}

export default utils
