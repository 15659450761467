<template>
  <div style="display:-webkit-box;-webkit-box-orient:vertical;height:100%;">
    <transition name="fade">
      <div class="loader-inner ui-header" v-show="loading">
        <div class=" line-scale-party">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="toast tips-inner ui-header" :class="tipsInfo.type===0?'toast-waring':'toast-success'" v-show="tipsInfo.message!==''">
        <div class="message">{{ tipsInfo.message }}</div>
      </div>
    </transition>
    <transition>
      <router-view class="child-view" v-model="initComplete"></router-view>
    </transition>
  </div>
</template>

<script type="text/javascript">
  import api from './js/api'
  import utils from './js/utils'
  import environment from './js/environment'

  export default {
    name: 'appVue',
    data () {
      return {
        tipsInfo: { message: '', type: 0 }, intervalId: null,
        loading: false, tips: '', initComplete: false, publicIp: environment.getPublic()
      }
    },
    created () {
      if (environment.type === 'development') {
        window.$api = api
      }
	  window.$event = api.event
      api.$layer = this.$layer
      utils.wechatConfig()
    },
    mounted () {
      const optionName = this.$options.name
      const api = this.$api
      api.$toast = this.$toast
      api.event.$on(optionName, 'app.tips', msg => this.tips = msg)
    },
    computed: {
      delay () {
        return this.$store.getters.getDelay
      },
      user () {
        return this.$store.getters.getUser
      }
    },
    destroyed () {
      console.log('app was destroyed !!')
      clearInterval(this.intervalId)
      this.$api.event.$off(this.$options.name)
    },
    methods: {},
    components: {}
  }
</script>
<style lang="scss">
	@import "style/app.scss";
</style>
<style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
  }

  .fade-enter, .fade-leave-to {
    opacity: 0
  }

  .loader-inner, .tips-inner {
    z-index: 100000;
    background: #fff;
    font-size: 13px;
    text-align: center;
    opacity: 0.8
  }

  .toast {
    background: #fff;
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
    top: calc((100vh - 50px) / 2);
    margin: 0 25px;
    width: calc(100% - 50px);
    border-radius: 6px
  }

  .toast-waring:before {
    background-color: #FFC007;
  }

  .toast-success:before {
    background-color: #2BDE3F;
  }

  .toast:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .toast-waring .message {
    color: #FFC007
  }

  .toast-success .message {
    color: #2BDE3F
  }

  .tips-inner {
    color: #ffa500
  }

  @-webkit-keyframes line-scale-party {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes line-scale-party {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .line-scale-party > div:nth-child(1) {
    -webkit-animation-delay: 0.77s;
    animation-delay: 0.77s;
    -webkit-animation-duration: 1.26s;
    animation-duration: 1.26s;
  }

  .line-scale-party > div:nth-child(2) {
    -webkit-animation-delay: 0.29s;
    animation-delay: 0.29s;
    -webkit-animation-duration: 0.43s;
    animation-duration: 0.43s;
  }

  .line-scale-party > div:nth-child(3) {
    -webkit-animation-delay: 0.28s;
    animation-delay: 0.28s;
    -webkit-animation-duration: 1.01s;
    animation-duration: 1.01s;
  }

  .line-scale-party > div:nth-child(4) {
    -webkit-animation-delay: 0.74s;
    animation-delay: 0.74s;
    -webkit-animation-duration: 0.73s;
    animation-duration: 0.73s;
  }

  .line-scale-party > div {
    background-color: #6795f7;
    background-color: #ffa500;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    -webkit-animation-name: line-scale-party;
    animation-name: line-scale-party;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-delay: 0;
    animation-delay: 0;
  }
</style>
