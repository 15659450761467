const MsConfig = {
  // 单元格宽度, 高度
  cellWidth: 66, cellHeight: 44,
  userType: {
	user: 'user', customer: 'customer', worker: 'worker', cleaner: 'cleaner'
  },
  defaultAvatar: '/static/images/avatar/avatar.png',
  roleNames: { storekeeper: '店主', staffer: '员工', manager: '管理员', investor: '投资人' },
  roomStatus: { Order: '订', CheckIn: '住', CheckOut: '退' },
  roomStatusDesc: { Order: '待入住', CheckIn: '入住中', CheckOut: '已退房', Clean: '未打扫', CleanStart: '保洁中', CleanEnd: '已打扫' },
  idType: { ID_CARD: '身份证', PASSPORT: '大陆护照', DRIVING_LICENSE: '大陆驾照', GAT_PERMIT: '港澳台通行证', HXZ: '回乡证', HK_ID: '香港身份证', OTHER_ID: '其他证件' },
  __ms_init_role: {
	messages: { order: true, roomState: true, clear: true, checkIn: true, system: true },
	functions: {
	  roomState: true, roomPrice: true, order: true, checkin: true, finance: false, employee: false, opLog: false, setting: false, shortMsg: true
	},
	restricts: { hiddenOrderPrice: false, hiddenCustomer: false, modifyOrder: false, deleteOrder: true, modifyCheckoutOrder: false, noCashPledge: true },
	storekeeper: {
	  messages: {}
	}
  },
  idCheckType: [
	{ name: '身份证件', value: 'idCard' }, { name: '身份证件+人脸', value: 'idCard,face' }, { name: '无需验证', value: '' }
  ],
  fangYiData: [
	{ name: '行程码', value: 'tripCode' }, { name: '健康码', value: 'healthCode' }, { name: '健康码+行程码', value: 'healthCode,tripCode' }, { name: '无需上传', value: '' }
  ],
  idCardData: [{ name: '仅头像', value: 'obverse' }, { name: '头像+国徽', value: 'obverse,reverse' }],
  vCropper: {
	img: '', show: false, info: true, size: 1, outputType: 'jpeg', canScale: true, autoCrop: true, autoCropWidth: 250, autoCropHeight: 250, fixed: true, infoTrue: true,
	fixedNumber: [1, 1]
  },
  OrderStatus: { Order: { color: '#ffc8b4' }, CheckOut: { color: '#d6d6d6' }, CheckIn: { color: '#f0dc8c' } },
  cleanStatus: { Clean: '未开始', CleanStart: '保洁中', CleanEnd: '已打扫' },
  cleanType: { standard: '标准', simple: '简单', deep: '深层' },
  cleanTypeDesc: { standard: '标准保洁', simple: '简单保洁', deep: '深层保洁' },
  errorMessage: {
	room_checkin_error_was_locked: '房间已有其它订单入住了，暂时无法操作！'
  }
}

function initListKeyVal (obj, name) {
  obj[name].keyVal = {}
  obj[name].forEach(type => {
	obj[name].keyVal[type.value] = type.name
  })
}

initListKeyVal(MsConfig, 'idCheckType')
initListKeyVal(MsConfig, 'idCardData')
initListKeyVal(MsConfig, 'fangYiData')

export default MsConfig
