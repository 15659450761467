<template>
  <li class="aui-list-header">
    <div class="aui-list-item-label-icon">
      <i class="iconfont aui-font-size-18" :class="iconName"/>
    </div>
    <div class="aui-list-item-inner">
      <div class="aui-list-item-input">
        {{labelName}}
        <slot name="inputText"></slot>
      </div>
      <div v-if="hasIcon" class="aui-list-item-label-icon" @click="$emit('icon-click')">
        <slot name="icon"></slot>
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    props: {
      labelName: { type: String, default: '' },
      hasIcon: { type: Boolean },
      iconName: { type: String }
    },
    name: 'LiHeader'
  }
</script>

<style scoped>
  .aui-list-header {
    padding: 0 0 0 .75rem
  }

  .aui-list-header .aui-list-item-label-icon {
    width: auto;
  }
</style>
