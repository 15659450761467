import Vue from 'vue'
import VueRouter from 'vue-router'
import api from '../js/api'
import Store from '../store'
import routerView from '../router.vue'
import MsConfig from '../js/ms.config'
import utils from '../js/utils'
import wx from 'weixin-js-sdk'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'home',
  component: routerView,
  children: [{ path: '/ms/index', name: 'MsIndexView', component: () => import('../views/MsIndexView.vue'), desc: '管理首页', meta: { index: 100 } }, // 二级菜单 公司设置
    { path: '/ms/setting', name: 'SettingView', component: () => import('../views/submenu/SettingListView.vue'), desc: '设置', meta: { index: 98 } }, // 二级菜单 入住管理
    { path: '/ms/ruzhu', name: 'RuZhuListView', component: () => import('../views/submenu/RuZhuListView.vue'), desc: '入住管理', meta: { index: 98 } }, // 二级菜单 财务管理
    { path: '/ms/finance', name: 'FinanceSubMenuView', component: () => import('../views/submenu/FinanceSubMenuView.vue'), desc: '财务菜单列表', meta: { index: 98 } }, // 管理房间
    { path: '/ms/room-list', name: 'RoomListView', component: () => import('../views/room/ListView.vue'), desc: '房间列表', meta: { index: 97 } },
    { path: '/ms/room-edit', name: 'RoomEditView', component: () => import('../views/room/EditView.vue'), desc: '房间编辑' },
    { path: '/ms/room-state', name: 'RoomStateView', component: () => import('../views/room/RoomStateView.vue'), desc: '房态管理' },
    { path: '/ms/room-types', name: 'RoomTypeListView', component: () => import('../views/room/RoomTypeListView.vue'), desc: '房型列表', meta: { index: 96 } },
    { path: '/ms/room-groups', name: 'RoomGroupListView', component: () => import('../views/room/RoomGroupListView.vue'), desc: '房组列表', meta: { index: 95 } }, // 订单
    { path: '/ms/order-edit', name: 'OrderEditView', component: () => import('../views/order/EditView.vue'), desc: '订单编辑' },
    { path: '/ms/order-list', name: 'MsOrderListView', component: () => import('../views/order/OrderListView.vue'), desc: '订单列表', meta: { index: 99 } },
    { path: '/ms/order-history', name: 'MsOrderHistoryView', component: () => import('../views/order/OrderHistoryList.vue'), desc: '订单历史', meta: { index: 98 } },
    // { path: '/ms/order-to-share', name: 'OrderToShareView', component: () => import('../views/order/ToShareView.vue'), desc: '入住分享' },
    { path: '/ms/lock/wifi-setting', name: 'LockWifiSetting', component: () => import('../views/submenu/LockQrNetWork.vue'), desc: '二维码配置网络', meta: { index: 98 } }, // 二级菜单 二维码配置网络
    // 员工
    { path: '/ms/employee-list', name: 'EmployeeListView', component: () => import('../views/employee/ListView.vue'), desc: '员工列表' },
    { path: '/ms/employee-edit', name: 'EmployeeEditView', component: () => import('../views/employee/EditView.vue'), desc: '员工编辑' },
    // 入住设置
    { path: '/ms/checkin', name: 'CheckinListView', component: () => import('../views/checkin/CheckinListView.vue'), desc: '入住设置' },
    { path: '/ms/global-setting', name: 'GlobalSettingView', component: () => import('../views/checkin/GlobalSetting.vue'), desc: '全局设置' },
    { path: '/ms/room-setting', name: 'RoomSettingListView', component: () => import('../views/checkin/RoomSettingList.vue'), desc: '房间设置' }, // 保洁管理
    { path: '/ms/room/clear-task', name: 'EditTaskView', component: () => import('../views/task/room/EditCleanTaskView.vue'), desc: '房间保洁' },
    { path: '/ms/room-task', name: 'RoomTaskView', component: () => import('../views/task/CleanList.vue'), desc: '保洁管理', meta: { index: 98 } },
    { path: '/ms/room-task/statistics', name: 'RoomTaskStatisticsView', component: () => import('../views/task/TaskStatistics.vue'), desc: '保洁统计' },
    { path: '/ms/room/cleaner-list', name: 'CleanerListView', component: () => import('../views/employee/CleanerListView.vue'), desc: '保洁员列表' },
    { path: '/ms/room-task/prices', name: 'RoomPricesSettingView', component: () => import('../views/task/room/RoomPriceSettingView'), desc: '价格设置' },
    { path: '/ms/room/cleaner-edit', name: 'CleanerEditView', component: () => import('../views/employee/EditCleaner.vue'), desc: '保洁员编辑' }, // 公司配置
    { path: '/ms/company-setting', name: 'CompanySettingView', component: () => import('../views/company/SettingView.vue'), desc: '公司配置' }, // 客户
    { path: '/customer/index', name: 'CustomerIndexView', component: () => import('../views/customer/IndexView.vue'), desc: '客户首页' },
    { path: '/customer/orders', name: 'CustomerOrdersView', component: () => import('../views/customer/OrderView.vue'), desc: '客户订单列表' },
    { path: '/customer/order-checkin/:code', name: 'CustomerOrderCheckInView', component: () => import('../views/customer/ShareOrderCodeView.vue'), desc: '客户订单填写' },
    { path: '/customer/order-checkin-link/:code', name: 'ShareOrderLinkView', component: () => import('../views/customer/ShareOrderLinkView.vue'), desc: '客户订单分享的链接' }, // 保洁
    { path: '/cleaner/task-check/:code', name: 'ShareTaskLinkView', component: () => import('../views/cleaner/ShareTaskLinkView'), desc: '任务分享的链接' },
    { path: '/cleaner/index', name: 'CleanerIndexView', component: () => import('../views/cleaner/CleanerIndexView.vue'), desc: '保洁员首页', meta: { index: 80 } },
    { path: '/cleaner/task', name: 'CleanerTaskListView', component: () => import('../views/cleaner/CleanerTaskListView'), desc: '每日任务' },
    { path: '/cleaner/setting', name: 'CleanerSettingView', component: () => import('../views/cleaner/CleanerSettingView.vue'), desc: '个人设置' }, // 平台管理
    { path: '/ms/company-platform', name: 'CompanyPlatformListView', component: () => import('../views/platform/ListView.vue'), desc: '公司平台列表' }, // 消息管理
    { path: '/ms/msg', name: 'MessageUserListView', component: () => import('../views/user-setting/message/ListView.vue'), desc: '消息管理' },
    { path: '/ms/msg-view', name: 'MessageUserDetailedView', component: () => import('../views/user-setting/message/DetailedView.vue'), desc: '消息查看' },
    { path: '/ms/yiqing', name: 'MsYiqingView', component: () => import('../views/yiqing/ListView.vue'), desc: '疫情防控区' },
    { path: '/ms/income', name: 'IncomeEditView', component: () => import('../views/in_exp/EditView.vue'), desc: '收入支出' },
    { path: '/ms/in-exp-order-list', name: 'IncomeListView', component: () => import('../views/in_exp/OrderListView.vue'), desc: '财务明细' },
    { path: '/ms/in-exp-other-list', name: 'IncomeOtherListView', component: () => import('../views/in_exp/OtherListView.vue'), desc: '其他收支' },
    { path: '/ms/equipment/room-list', name: 'DeviceRoomListView', component: () => import('../views/equipment/RoomListView.vue'), desc: '房号设备列表' },
    { path: '/ms/equipment/room-binding', name: 'BindingView', component: () => import('../views/equipment/BindingView.vue'), desc: '绑定设备' },
    { path: '/ms/log-info', name: 'LogInfoListView', component: () => import('../views/loginfo/ListView.vue'), desc: '操作日志' },
    { path: '/ms/lock-log', name: 'LogLockInfoListView', component: () => import('../views/loginfo/LockLogView.vue'), desc: '开锁日志' },
    { path: '/ms/customer/customer-order-info-list', name: 'CustomerOrderInfoListView', component: () => import('../views/customer/CustomerOrderInfoListView.vue'), desc: '操作日志' },
    { path: '/ms/customer/checkout-list', name: 'CustomerOrderCheckoutListView', component: () => import('../views/customer/CustomerOrderCheckoutListView.vue'), desc: '退房管理' },
    { path: '/ms/user/setting', name: 'MsUserSettingView', component: () => import('../views/user-setting/UserSettingView.vue'), desc: '个人设置' },
    { path: '/ms/apply-lock', name: 'ApplyLock', component: () => import('../views/room/SmartLockRooms.vue'), desc: '申请开锁' },
    { path: '/ms/finance-report', name: 'FinanceReportView', component: () => import('../views/report/FinanceReportView.vue'), desc: '财务报表' },
    { path: '/ms/finance-day-report', name: 'FinanceDayReportView', component: () => import('../views/report/FinanceDayReportView.vue'), desc: '财务夜审报表' },
    { path: '/ms/police', name: 'policeListView', component: () => import('../views/police/ListView.vue'), desc: '大河备案日志' },
    { path: '/worker/binding', name: 'workerBindingView', component: () => import('../views/worker/BindingView.vue'), desc: '门锁工作人员绑定锁' }
  ]
},
  //  { path: '/about', name: 'about', component: () => import('../views/AboutView.vue') },
  { path: '/wechat-auth/:token', name: 'WechatAuthView', component: () => import('../views/WechatAuth.vue'), desc: '微信公众号验证' },
  { path: '/welcome', name: 'WelcomeView', component: () => import('../views/Welcome.vue') },
  { path: '/article/:id', name: 'ArticleView', component: () => import('../views/ArticleView.vue') },
  { path: '/logout', name: 'LogoutView', component: () => import('../views/LogoutView.vue') },
  { path: '/register/:token', name: 'RegisterView', component: () => import('../views/Register.vue') },
  { path: '/ms/power', name: 'powerIndex', component: () => import('../views/power/index.vue'), desc: '智能节电' },
  { path: '/ms/power/cam', name: 'powerCam', component: () => import('../views/power/cam.vue'), desc: '房间设备' },
  { path: '/ms/power/camSave', name: 'camSave', component: () => import('../views/power/cam-save.vue'), desc: '设备新增' },
  { path: '/ms/power/info', name: 'powerInfo', component: () => import('../views/power/info.vue'), desc: '设备详情' },
  { path: '/ms/message', name: 'messageIndex', component: () => import('../views/message/index.vue'), desc: '消息列表' },
  { path: '/ms/message/info', name: 'messageInfo', component: () => import('../views/message/info.vue'), desc: '消息详细' },
  { path: '/ms/message/center', name: 'messageInfo', component: () => import('../views/message/center.vue'), desc: '消息中心' },
  { path: '/ms/message/show', name: 'messageInfo', component: () => import('../views/message/show.vue'), desc: '消息详细' },
]

const router = new VueRouter({
  mode: 'hash', base: process.env.BASE_URL, routes
})

const noHistoryPath = ['/welcome', '/user/login', '/logout', '/register', '/regulation', '/wechat-auth', '/register', '/article']

function validatePath (path) {
  const httpVersion = () => api.httpData.workerVersion()
  return httpVersion().then(nvl => {
    const fun = api.$store.state.fun
    const funPath = fun.paths.find(p => p.link === path)
    if (funPath) {
      return funPath.usable
    } else {
      return true
    }
  })
}

router.beforeEach((to, from, next) => {
  api.getSecurity().then(() => {
    const path = to.path
    const needLogin = !noHistoryPath.find(pPath => path.startsWith(pPath))
    let type = null
    if (needLogin) {
      if (path.startsWith('/ms/')) {
        const user = Store.getters.getUser
        window.userType = type = MsConfig.userType.user
        if (user.complete) {
          validatePath(path).then(value => {
            if (value || path === '/ms/index') {
              return next()
            } else {
              return api.$router.replace('/ms/index')
            }
          })
        } else {
          api.wechatLogin(type)
        }
      } else if (path.match(/^\/(customer|worker|cleaner)/)) {
        window.userType = type = MsConfig.userType.customer
        const customer = Store.getters.getCustomer
        if (customer.complete) {
          next()
        } else {
          api.wechatLogin(type)
        }
      }
    } else {
      next()
    }
  })
})

const wechatMenus = ['menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:weiboApp', 'menuItem:favorite', 'menuItem:share:facebook', 'menuItem:share:QZone', 'menuItem:openWithSafari', 'menuItem:openWithQQBrowser']

router.afterEach(() => {
  if (api.isWechatBrowser()) {
    utils.wechatReady().then(() => wx.hideMenuItems({ menuList: wechatMenus }))
  }
})

export default router
