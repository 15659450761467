const components = require.context('./', false, /\.vue$/)

// components.keys().map(item => {
//   Vue.components
// })

export default Vue => {
  components.keys().forEach(item => {
    const model = components(item)
    // console.log(model.default.name, model)
    Vue.component(model.default.name, model.default)
  })
}
