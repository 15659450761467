<template>
  <div :id="'layui-m-layer' + uuid" class="layui-m-layer" :class="'layui-m-layer'+ type" :index="uuid" v-show="visible">
	<div @click="shadeCloseFn" v-show="shade" class="layui-m-layershade"></div>
	<div class="layui-m-layermain" :style="!fixed ? 'position:static;' : ''">
	  <div class="layui-m-layersection">
		<div class="layui-m-layerchild" :class="layerClass" :style="styles" :opt-type="optType" :alert-type="alertType">
		  <h3 v-if="aTitle" :type="type" :style="aTitle[1]">
			<i class="iconfont icon-prompt iconfont-rotate-180 aui-font-size-20 aui-margin-r-5" :class="icon"/>
			{{ aTitle[0] }}
		  </h3>
		  <template v-if="!!content">
			<div v-if="type != 2 && optType==='confirm'" class="layui-m-layercont" v-html="content"></div>
			<div v-else-if="type != 2 && optType==='alert'" class="layui-m-layercont">
			  <i v-if="alertType==='Error'" class="iconfont icon-error aui-font-size-22"/>&nbsp; <span v-html="content"/>
			</div>
			<div v-else class="layui-m-layercont">
			  <i></i><i class="layui-m-layerload"></i><i></i>
			  <div v-if="optType==='confirm'">{{ content }}</div>
			  <div else v-html="content"></div>
			</div>
		  </template>
		  <template v-else>
			<div v-if="type != 2" class="layui-m-layercont">
			  <slot></slot>
			</div>
			<div v-else class="layui-m-layercont" id="layui-m-layercont">
			  <i></i><i class="layui-m-layerload"></i><i></i>
			  <div>
				<slot></slot>
			  </div>
			</div>
		  </template>
		  <div v-if="btns" class="layui-m-layerbtn">
			<span class="flex-line-height" @click.stop="handleClick($event)" v-if="btns[1]" no data-type="0">{{ btns[1] }}</span>
			<span class="flex-line-height" @click.stop="handleClick($event)" yes data-type="1">{{ btns[0] }}</span>
		  </div>
		</div>
	  </div>
	</div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Layer',
  props: {
	uuid: {
	  type: Number,
	  default: +((Math.random().toString()).substr(-6))
	},
	value: {
	  type: Boolean,
	  default: false
	},
	title: {
	  type: null,
	  default: false
	},
	btn: {
	  type: null,
	  default: false
	},
	top: {
	  type: Number,
	  default: 100
	},
	time: Number,
	skin: String,
	className: String,
	styles: String,
	content: String,
	// 设置弹层的类型（0表示信息框，1表示页面层，2表示加载层）
	type: {
	  type: Number,
	  default: 0
	},
	icon: {
	  type: String,
	  default: ''
	},
	optType: {
	  type: String,
	  default: null
	},
	alertType: {
	  type: String,
	  default: null
	},
	// 控制遮罩展现
	shade: {
	  type: Boolean,
	  default: true
	},
	shadeClose: {
	  type: Boolean,
	  default: true
	},
	fixed: {
	  type: Boolean,
	  default: true
	},
	anim: {
	  type: [String, Boolean],
	  default: 'scale', // 默认动画类型
	  validator (val) {
		return [
		  false,
		  'scale',
		  'up'
		].indexOf(val) !== -1
	  }
	},
	// 层成功弹出层的回调
	success: Function,
	// 点确定按钮触发的回调函数
	yes: Function,
	// 点取消按钮触发的回调函数
	no: Function,
	// 层彻底销毁后的回调函数
	end: Function
  },
  data () {
	return {
	  visible: false
	}
  },
  created () {
	this.timeId = null
	if (this.value) {
	  this.visible = true
	}
  },
  destroyed () {
	if (this.timeId) {
	  clearTimeout(this.timeId)
	}
  },
  computed: {
//            visible() {
//                return this.value
//            },
	layerClass () {
	  const that = this
	  const classList = []

	  // 设定弹层显示风格css类
	  if (that.skin) {
		classList.push('layui-m-layer-' + that.skin)
	  }
	  // 自定义一个css类
	  if (that.className) {
		classList.push(that.className)
	  }
	  // 动画css类
	  if (that.anim) {
		classList.push('layui-m-anim-' + that.anim)
	  }

	  return classList.join(' ')
	},
	aTitle () {
	  const that = this
	  let aTitle = that.title
	  typeof aTitle === 'string' && (aTitle = [aTitle])
	  const len = (aTitle || []).length

	  if (len === 0 || !that.btn) {
		return ''
	  }
	  return aTitle
	},
	btns () {
	  const that = this
	  let btn = that.btn

	  typeof btn === 'string' && (btn = [btn])
	  const btns = (btn || []).length

	  if (btns === 0 || !that.btn) {
		return ''
	  }

	  return (btn = btn.slice(0, 2))
	}
  },
  methods: {
	// 点击遮罩层事件处理
	shadeCloseFn () {
	  const that = this

	  if (that.shade && that.shadeClose) {
		this.$emit('close')
	  }
	},
	// 按钮事件处理
	handleClick (ev) {
	  const btnTyps = +ev.target.dataset.type
	  let eventName
	  if (btnTyps === 1) {
		eventName = 'sure'
	  } else {
		eventName = 'cancel'
	  }
	  this.$emit(eventName)
	}
  },
  watch: {
	visible (val) {
	  const that = this
	  if (val && that.time >= 0) {
		clearTimeout(that.timeId)
		that.$emit('input', true)
		that.$emit('show')

		that.timeId = setTimeout(() => {
		  that.$emit('input', false)
		  that.$emit('close')
		}, that.time * 1000)
	  }
	},
	value (val) {
	  this.visible = val
	}
  }
}
</script>

<style scoped>
@import "layer.css";
</style>
<style scoped>
/*  opt-type  */
.layui-m-layerchild[opt-type] {
  color: #8f8888
}

.layui-m-layerchild[opt-type] h3 {
  text-align: left;
  background: #fff;
  padding: 0 10px 0 15px;
  font-weight: 900;
  color: #000
}

.layui-m-layerchild[opt-type] h3 .aui-iconfont {
  color: #3a8be6;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
}

.layui-m-layerchild[opt-type='confirm'] .layui-m-layercont {
  padding: 0 20px 20px;
  font-size: 16px;
  color: #686868
}

.layui-m-layerchild[opt-type='alert'] .layui-m-layercont {
  padding: 35px 15px 20px !important;
  font-size: 16px;
}

.layui-m-layerchild[opt-type] .layui-m-layerbtn {
  background: #fff;
  font-weight: bold;
  border: 0
}

.layui-m-layerchild[opt-type] .layui-m-layerbtn span {
  font-size: 16px;
  line-height: 50px;
}

.layui-m-layerchild[opt-type] .layui-m-layerbtn span[no] {
  border-right: 0px solid #D0D0D0;
  color: #9a9a9a;
}

.layui-m-layerchild[opt-type] .layui-m-layerbtn span[yes] {
  color: #151515;
}

.layui-m-layerchild[opt-type] .layui-m-layerbtn::before {
  z-index: 1;
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 1px;
  color: rgba(0, 0, 0, .1);
  border-top: 1px solid rgba(0, 0, 0, .1);
  transform-origin: 50% 0;
  transform: scaleY(.5);
}

.layui-m-layerchild[opt-type='msg'] .layui-m-layercont {
  padding: 12px 20px 10px 35px;
  position: relative;
  color: #fff;
  font-size: 16px
}

.layui-m-layerchild[opt-type='msg'] .layui-m-layercont::before {
  content: '\e6ed';
  font-family: "aui_iconfont" !important;
  position: absolute;
  z-index: 20;
  top: 7px;
  left: 10px;
  font-size: 18px;
  line-height: 1.4rem;
  transform: rotate(180deg);
}

.layui-m-layerchild[alert-type='Error'] .layui-m-layercont {
  padding-left: 42px;
  display: flex;
  justify-content: center;
}

.layui-m-layerchild[alert-type='Error'] .layui-m-layercont i.iconfont {
  color: #d60808;
  font-size: 24px
}

</style>
