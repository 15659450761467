import Vue from 'vue'
import Vuex from 'vuex'
import comm from './modules/comm'
import fun from './modules/functions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { comm, fun },
  strict: process.env.NODE_ENV !== 'production'
})
