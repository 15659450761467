import Vue from 'vue'
import vueJsonp from 'vue-jsonp'
import './registerServiceWorker'
import App from './App.vue'
import api from './js/api'
import router from './router/index'
import store from './store/index'
import wx from 'weixin-js-sdk'
import zhCN from 'vee-validate/dist/locale/zh_CN'
import VeeValidate from 'vee-validate'
import VueI18n from 'vue-i18n'
import FastClick from 'fastclick'

import layer from './components/layer/layer'
import SideNavBar from './components/sidenavbar-win/sidenavbar'
import NavHeader from './components/nav-header'
import WinSelect from './components/win-select'
import ZeroList from './components/zero-list'
import LiComponents from './components/cell-list-item'

// import VueQuillEditor from 'vue-quill-editor'
// Vue.use(VueQuillEditor)
import {
  Calendar,
  Circle,
  DatetimePicker,
  Dialog,
  Divider,
  DropdownItem,
  DropdownMenu,
  Image as VanImage,
  ImagePreview,
  Lazyload,
  List,
  Loading,
  NoticeBar,
  Popover,
  Popup,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Toast,
  Uploader,
  NavBar,
  Icon,
  Empty,
  ActionSheet,
  Col,
  Row,
  Button,
  Tag,
  Progress,
  Cell,
  CellGroup,
  Search,
  Sticky,
  Form,
  Field,
  Picker

} from 'vant'

Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']
const components = [
  layer, NavHeader, SideNavBar, WinSelect, VueI18n, vueJsonp, Calendar, Uploader, Popup, Dialog, DropdownMenu,
  DropdownItem, List, Popover, NoticeBar, Tab, Tabs, Toast,
  ImagePreview, DatetimePicker, Lazyload, Swipe, SwipeItem, ZeroList, LiComponents, Divider, Circle, Loading,
  VanImage, NavBar, Icon, Empty,ActionSheet, Col, Row,Button,Tag,Progress,Cell, CellGroup,Search,Sticky,Form, Field,Picker
]
components.forEach(component => Vue.use(component))

// const data = {
//  el: '#app', router, store,  template: '<App/>',
//  components: { App }
// }

const i18n = new VueI18n({
  locale: 'zh_CN'
})

Vue.use(VeeValidate, {
  i18n,
  i18nRootKey: 'validation',
  dictionary: {
    zhCN
  }
})

api.$store = store
Vue.prototype.$api = api
Vue.prototype.$wx = wx
api.$router = router

FastClick.attach(document.body)
//
FastClick.prototype.onTouchEnd = function(event) {
  const input = event.target,
    tagName = input.tagName
  if (tagName.toLowerCase() === 'textarea' || (input.type && input.type.match(/text|password|number/))) {
    event.preventDefault()
    return false
  }
}

FastClick.prototype.focus = function(targetElement) {
  // Issue #160: on iOS 7, some input elements (e.g. date datetime month) throw a vague TypeError on setSelectionRange.
  // These elements don't have an integer value for the selectionStart and selectionEnd properties,
  // but unfortunately that can't be used for detection because accessing the properties also throws a TypeError.
  // Just check the type instead. Filed as Apple bug #15122724
  const deviceIsWindowsPhone = navigator.userAgent.indexOf('Windows Phone') >= 0
  const deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone
  let length
  if (deviceIsIOS && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement
    .type !== 'time' && targetElement.type !== 'month') {
    length = targetElement.value.length
    targetElement.focus()
    targetElement.setSelectionRange(length, length)
  } else {
    targetElement.focus()
  }
}

Date.prototype.toJSON = function() {
  return this.getTime()
}

Vue.directive('longpress', {
  bind: function(el, binding, vNode) {
    // console.log(binding.value, binding.arg, vNode)
    // 确保提供的表达式是函数
    if (typeof binding.value !== 'function') {
      // 获取组件名称
      // const compName = vNode.context.name
      // let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`
      // if (compName) {
      //   warn += `Found in component '${compName}'`
      // }
      // console.warn(warn)
    }
    // 定义变量
    let pressTimer = null
    // 创建计时器（ 1秒后执行函数 ）
    const start = (e) => {
      if (e.type === 'click' && e.button !== 0) {
        return
      }
      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          // 执行函数
          handler(e)
        }, 1000)
      }
    }
    // 取消计时器
    const cancel = () => {
      if (pressTimer !== null) {
        clearTimeout(pressTimer)
        pressTimer = null
      }
    }
    // 运行函数
    const handler = (e) => {
      if (binding.value) {
        vNode.context[binding.value.fun](binding.value.params)
        // binding.value(e)
      } else {
        // binding.expression
      }
    }
    // 添加事件监听器
    el.addEventListener('mousedown', start)
    el.addEventListener('touchstart', start)
    // 取消计时器
    el.addEventListener('click', cancel)
    el.addEventListener('mouseout', cancel)
    el.addEventListener('touchend', cancel)
    el.addEventListener('touchcancel', cancel)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
