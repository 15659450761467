function resetConfig (config, api) {
  // Object.assign(this, config)
}

const environment = {
  // 开发环境设置为 development, 生产环境设置 production
  type: 'development',
  development: {
    key: '',
    domain: 'https://yaz.ykmao.cn',
    port: '80',
    wsPort: '12020',
    imageServer: 'https://yaz.ykmao.cn',
    public: 'https://yaz.ykmao.cn',
    cluster: 'https://yaz.ykmao.cn',
    pub: 'https://yaz.ykmao.cn',
    wechat: 'https://yaz.ykmao.cn',
    top: '',
    sub: '',
    subDomain: '',
    resetConfig: resetConfig
  },
  // development: {
  //   key: '',
  //   domain: 'http://service.ykmao.com.cn',
  //   port: '80',
  //   wsPort: '12020',
  //   imageServer: 'http://img.ykmao.cn',
  //   public: 'http://public.ykmao.cn',
  //   cluster: 'http://service.ykmao.com.cn',
  //   pub: 'http://public.ykmao.com.cn', //public.ykmao.com.cn
  //   wechat: 'http://service.ykmao.com.cn',
  //   top: '',
  //   sub: '',
  //   subDomain: '',
  //   resetConfig: resetConfig
  // },
  production: {
    key: '',
    domain: 'http://service.ykmao.com.cn',
    port: '80',
    wsPort: '12020',
    imageServer: 'http://img.ykmao.cn',
    public: 'http://service.ykmao.com.cn',
    cluster: 'http://service.ykmao.com.cn',
    pub: 'http://service.ykmao.com.cn', //public.ykmao.com.cn
    wechat: 'http://service.ykmao.com.cn',
    top: '',
    sub: '',
    subDomain: '',
    resetConfig: resetConfig
  },
  // production: {
  //   key: '',
  //   domain: 'http://test.ykmao.cn',
  //   port: '80',
  //   wsPort: '12020',
  //   imageServer: 'http://test.ykmao.cn',
  //   public: 'http://test.ykmao.cn',
  //   cluster: 'http://test.ykmao.cn',
  //   pub: 'http://test.ykmao.cn',
  //   wechat: 'http://test.ykmao.cn',
  //   top: '',
  //   sub: '',
  //   subDomain: '',
  //   resetConfig: resetConfig
  // },
  // production: {
  //   key: '',
  //   domain: 'http://m8oktqpwegwc.guyubao.com',
  //   port: '80',
  //   wsPort: '12020',
  //   imageServer: 'http://127.0.0.1',
  //   public: 'http://ydz1k4z5s1v9.guyubao.com',
  //   cluster: 'http://ydz1k4z5s1v9.guyubao.com',
  //   pub: 'http://ydz1k4z5s1v9.guyubao.com',
  //   wechat: 'http://ydz1k4z5s1v9.guyubao.com',
  //   top: '',
  //   sub: '',
  //   subDomain: '',
  //   resetConfig: resetConfig
  // },
  development2: {
    key: '',
    domain: 'http://192.168.2.76:8081',
    port: '80',
    wsPort: '12020',
    imageServer: 'http://192.168.2.76',
    public: 'http://192.168.2.76:8083',
    cluster: 'http://192.168.2.76:8083',
    pub: 'http://192.168.2.76:8083',
    wechat: 'http://192.168.2.76:8083',
    top: '',
    sub: '',
    subDomain: '',
    resetConfig: resetConfig
  },
  getDomain () {
    return this[this.type].domain
  },
  getPort () {
    return this[this.type].port
  },
  getPublic () {
    return this[this.type].public
  },
  getImageServer () {
    return this[this.type].imageServer
  },
  getWsUrl () {
    const ip = this.getDomain().replace(/http:\/\/|https:\/\//, '').split(':')[0]
    return 'ws://' + ip + ':' + this.get().wsPort
  },
  getWeChatUrl () {
    return this[this.type].wechat
  },
  get () {
    return this[this.type]
  }
}

export default environment
